import React from 'react';
import Particles from "react-tsparticles";
import Alps from "../Assets/alps.jpg";


//Photo creds:
//Photo by <a href="https://freeimages.com/photographer/alpeanden-41701">Anders Bjerré Pedersen</a> from <a href="https://freeimages.com">FreeImages</a>

const MakeItSnow = () => {
    return (
        <div style={{
            position: "absolute !important",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
          }}
        >
            <Particles 
                options={{
                background: {
                    color: {
                        value: "#FFFF",
                    },     
                    image: `url(${Alps})`, 
                    size: 'cover',            
                },
                responsive: [ 
                    {
                      // Disable bubble mode on mobile  
                      maxWidth: 767,
                      options: {
                        interactivity: {
                          events: {
                            onClick: {
                              enable: false,
                            },
                          }
                        }
                      }
                    }
                ],
                backgroundMask: {
                    composite: "destination-out",
                    cover: {
                        color: {
                            value: "#FFFF",
                        },
                        opacity: 0,
                    },
                    enable: false,
                },
                fpsLimit: 60,
                fullScreen: {
                    enable: true,
                    zIndex: 0,
                },
                duration: 0,
                interactivity: {
                    events: {
                    onClick: {
                        enable: true,
                        mode: "bubble",
                    },
                    onHover: {
                        enable: false,
                        mode: "bubble",
                    },
                    onDiv: {
                        enable: false,
                        type: "circle",
                    },
                    resize: true,
                    },
                    modes: {
                    bubble: {
                        distance: 400,
                        duration: 0.5,
                        opacity: 1,
                        size: 4,
                        mix: false,
                    },
                    push: {
                        quantity: 4,
                    },
                    repulse: {
                        distance: 200,
                        duration: 0.4,
                    },
                    },
                },
                particles: {
                    bounce: {
                        horizontal: {
                            random: {
                                enable: false,
                                minimumValue: 0.1,
                            },
                            value: 1,
                        },
                        vertical: {
                            random: {
                                enable: false,
                                minimumValue: 0.1,
                            },
                            value: 1,
                        },
                    },
                    collisions: {
                        enable: false,
                        mode: "bounce",
                        overlap: {
                            enable: true,
                            retries: 0,
                        }
                    },
                    color: {
                        value: "#ffffff",
                        animation: {
                            h: {
                                count: 0,
                                enable: false,
                                offset: 0,
                                speed: 1,
                                sync: true,
                            },
                            s: {
                                count: 0,
                                enable: false,
                                offset: 0,
                                speed: 1,
                                sync: true,
                            },
                            l: {
                                count: 0,
                                enable: false,
                                offset: 0,
                                speed: 1,
                                sync: true,
                            },
                        }
                    },
                    destroy: {
                        mode: "none",
                        split: {
                            count: 1,
                            factor: {
                                random: {
                                    enable: false,
                                    minimumValue: 0,
                                },
                                value: 3,
                            },
                            rate: {
                                random: {
                                    enable: false,
                                    minimumValue: 0,
                                },
                                value: {
                                    min: 4,
                                    max: 9,
                                },
                            },
                            sizeOffset: true,
                        },
                    },
                    life: {
                        count: 0,
                        delay: {
                            random: {
                                enable: false,
                                minimumValue: 0,
                            },
                            value: 0,
                            sync: false,
                        },
                        duration: {
                            random: {
                                enable: false,
                                minimumValue: 0.0001,
                            },
                            value: 0,
                            sync: false,
                        }
                    },
                    line_linked: {
                        color: "#ffffff",
                        enable: false,
                        distance: 50,
                        opacity: 0.5,
                        width: 1,
                    },
                    move: {
                        enable: true,
                        speed: 2,
                        direction: "bottom",
                        random: true,
                        straight: false,
                        outMode: "out",
                        bounce: false,      
                    },                    
                    number: {
                        density: {
                            enable: true,
                            area: 800,
                            factor: 1000,
                        },
                        limit: 0,
                        value: 1200,
                    },
                    opacity: {
                        value: 0.8,
                    },
                    orbit: {
                        enable: false,
                        opacity: 1,
                        animation: {
                            count: 0,
                            enable: false,
                            speed: 1,
                            sync: false,
                        },
                        rotation: {
                            value: 45,
                            random: {
                                enable: false,
                                minimumValue: 0,
                            },
                        },
                        width: 1,                        
                    },
                    reduceDuplicates: false,
                    shape: {
                        type: "circle",
                    },
                    rotate: {
                        random: {
                            enable: false,
                            minimumValue: 0,
                        },
                        value: 0,
                        animation: {
                            enable: false,
                            speed: 0,
                            sync: false,
                        },
                        direction: 'clockwise',
                        path: false,
                    },
                    size: {
                        random: true,
                        value: 3,
                        anim: {
                            enable: false,
                            speed: 20,
                            size_min: 0.1,
                            sync: false,
                        },
                    },
                },
                motion: {
                    disable: false,
                    reduce: {
                        factor: 4,
                        value: true,
                    }
                },
                detectRetina: true,
                }}
            />
        </div>
    );
}

export default MakeItSnow;