import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next) 
  .init({ 
    supportedLngs: ['en', 'sv', 'ar'],
    fallbackLng: "en",
    debug: false,
    detection: {
      order: ['path', 'cookie', 'htmlTag'],
      caches: ['cookie'],
    },
    backend: {
      loadPath: '/locales/{{lng}}/translation.json' 
    },
    react: {
      useSuspense: true
    },
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

  export default i18n;