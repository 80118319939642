import { useState, useEffect } from "react";

//Convert absolute width into breakpoints
const getBreakPoint = (windowWidth) => { //TODO: make enum instead
    if (windowWidth) {
      if (windowWidth <= 600) 
        return "mobile";
       else if (windowWidth <= 767) 
        return "ipad";
       else if (windowWidth <= 991) 
        return "medDevice";
       else if (windowWidth <= 1199) 
        return "laptop";
       else 
        return "desktop";
    } else 
      return "desktop";   
}

const useWindowSize = () => {
    const isWindowClient = typeof window === "object";
  
    const [windowSize, setWindowSize] = useState(
      isWindowClient ? getBreakPoint(window.innerWidth) : undefined
    );
  
    useEffect(() => {
      //a handler which will be called on change of the screen resize
      function setSize() {
        setWindowSize(getBreakPoint(window.innerWidth));
      }
  
      if (isWindowClient) {
        //register the window resize listener
        window.addEventListener("resize", setSize);
  
        //unregister the listener
        return () => window.removeEventListener("resize", setSize);
      }
    }, [isWindowClient, setWindowSize]);
  
    return windowSize;
}

export default useWindowSize;