import React, { useState } from 'react';
import './navbar.style.css';
import { Link } from 'react-router-dom'

import { useTranslation } from "react-i18next";
import i18next from 'i18next';

import { Close, MenuOutlined } from '@material-ui/icons';

const Navbar = () => { 
    const { t} = useTranslation();
    const [active, setActive] = useState(false);

    const showMenu = () => {
        setActive(!active);
    }

    const getCurrentLng = () => i18next.language || window.localStorage.i18nextLng || ''; //Ugly workaround to text sizes being too large for container in some languages

    return (
        <div>
            <div className="mobileNavbar">
                <div className="menu-icon">
                    <MenuOutlined className="menu" onClick={showMenu}/>
                </div>
                <nav className={active ? "slider active" : "slider"}>
                    <ul onClick={showMenu}>
                        <div className="closedMobileNavbar">
                            <Close className="close" onClick={showMenu}></Close>
                        </div>
                        <li>
                            <Link to="/">
                                <p>{t('navbarHome')}</p>
                            </Link>
                        </li>
                        <li>
                            <Link to="/about">
                                <p>{t('navbarAbout')}</p>
                            </Link>
                        </li>
                        <li>
                            <Link to="/skills">
                                <p>{t('navbarSkills')}</p>
                            </Link>
                        </li>
                        <li>
                            <p className="disabled">{t('navbarProjects')}</p>
                        </li>
                    </ul>
                </nav>
            </div>

            <div className="desktopNav">
                <div className="boxContainer">
                    <Link to="/">
                        <div className="box">
                            <div className="front face">
                                <i className="fa fa-home" style={{color: "#fff"}}></i>
                            </div>
                            <div className="right face">
                                {getCurrentLng() === "sv"? 
                                    <p className="textStyle" style={{fontSize: "80%"}}>{t('navbarHome')}</p> :
                                    <p className="textStyle">{t('navbarHome')}</p>
                                }
                            </div>
                        </div>
                    </Link>
                </div>

                <div className="boxContainer">
                    <Link to="/about">
                        <div className="box">
                            <div className="front face">
                                <i className="fa fa-info" style={{color: "#fff"}}></i>
                            </div>
                            <div className="right face">
                                <p className="textStyle">{t('navbarAbout')}</p>
                            </div>
                        </div>
                    </Link>
                </div>

                <div className="boxContainer">
                    <Link to="/skills">
                        <div className="box">
                            <div className="front face">
                                <i className="fa fa-connectdevelop" style={{color: "#fff"}}></i>
                            </div>
                            <div className="right face">
                                
                                {getCurrentLng() === "sv"? 
                                    <p className="textStyle" style={{fontSize: "65%"}}>{t('navbarSkills')}</p> :
                                    <p className="textStyle">{t('navbarSkills')}</p>
                                }
                            </div>
                        </div>
                    </Link>
                </div>

                <div className="boxContainer">
                    {/* <Link to="/projects"> */}
                        <div className="box noPointer">
                            <div className="front face">
                                <i className="fa fa-file-code-o disabled" style={{color: "#fff"}}></i>
                            </div>
                            <div className="right face">
                                {getCurrentLng() === "ar"? 
                                    <p className="textStyle disabled" style={{fontSize: "85%"}}>{t('navbarProjects')}</p> :
                                    <p className="textStyle disabled">{t('navbarProjects')}</p>
                                }
                            </div>
                        </div>
                    {/* </Link> */}
                </div>
            </div>
        </div>
    );
}

Navbar.propTypes = {};

export default Navbar;