import React, { useState, useEffect } from 'react';
import './landing.style.css';
import { ThemeProvider } from 'styled-components';
import styled from 'styled-components';
import { IoThunderstorm } from 'react-icons/io5';
import { BsSnow, BsFillSunFill } from 'react-icons/bs';
import { VscUnmute, VscMute } from 'react-icons/vsc';
import { RiCreativeCommonsLine } from 'react-icons/ri';
import 'animate.css';

import MakeItSnow from '../../Helpers/makeitsnow';
import { useTranslation } from "react-i18next";
import ReactHowler from 'react-howler';
import SnowSound from "../../Assets/snowSound.mp3"; //Sound from Zapsplat.com
import HeavyRainSound from "../../Assets/heavyRain.mp3";
import ThunderClapSound from "../../Assets/thunderClap.mp3";
import SunSound from "../../Assets/sunSound.mp3";

import CreditsModal from '../../Components/CreditsModal/creditsModal';
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";


const SunnyWeather = {
    pageBackground: "#b2cdfc",
    titleColor: "#393e46",
    tagLineColor: "black",
    textGradient: "linear-gradient(90deg, rgba(0,0,0,1) 100%, rgba(0,0,0,1) 100%)"
};

const RainyWeather = {
    pageBackground: "#282c36",
    titleColor: "#393e46",
    tagLineColor: "lavender",
    textGradient: "linear-gradient(31deg, rgba(255,255,255,1) 30%, rgba(126,121,109,1) 41%, rgba(60,59,59,1) 60%)"
};

const SnowyWeather = {
    pageBackground: "#282c36",
    titleColor: "#393e46",
    tagLineColor: "black",
    textGradient: "linear-gradient(90deg, rgba(57,62,70,1) 100%, rgba(57,62,70,1) 100%)"
};

const weatherTypes = {
    sun: SunnyWeather,
    rain: RainyWeather,
    snow: SnowyWeather,
}

const Page = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
    background-color: ${props => props.theme.pageBackground};
    transition: all .5s ease;
`;

const ToggleContainer = styled.div`
    display: inline-block;
    flex-direction: column;
    align-items: center; 
    position: absolute;
    bottom: 5%;
    left: 5%;
`;

const Toggle = styled.button`
    cursor: pointer;
    height: 50px;
    width: 50px;
    border-radius: 50%;
    border: none;
    color: ${props => props.theme.pageBackground};
    &:focus {
        outline: none;
    }
    transition: all .5s ease;
    font-family: "neuemachina-ultrabold" !important;
    font-size: 2vh !important;
`;

const IntroText = styled.header`
    margin: 0 0;
    font-size: 12vh;  
    font-family: "neuemachina-ultrabold"; 
    font-style: normal; 
    font-weight: bold;
    outline: none;
    letter-spacing: -0.01em;      
    line-height: normal !important;
    background-image: ${props => props.theme.textGradient};
    background-size: 100%;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -webkit-text-fill-color: transparent; 
    -moz-text-fill-color: transparent;
`;

const LandingPage = () => {
    const [weather, setWeather] = useState("snow");
    const [isLoading, setIsLoading] = useState(true);
    const [playing, setPlaying] = useState(false);
    const [volume, setVolume] = useState(0.5);
    const [isCreditsVisible, setCreditsVisible] = useState(false);
    
    const { t} = useTranslation();
    
    const [creditsArray, setCreditsArray] = useState(['snowImageCredits', 'snowSoundCredits']);

    const onTogglePlay = () => {
        setPlaying(!playing);
    };

    const toggleCredits = () => {
        setCreditsVisible(!isCreditsVisible);
    }

    useEffect(()=>{
        const handleLoading = () => {
            setIsLoading(false);
        };

        if (document.readyState === "complete") {
            handleLoading();
        } else {
            window.addEventListener("load",handleLoading);
            return () => window.removeEventListener("load",handleLoading);
        }
    },[]);

    function setSunnyWeather() {
        setWeather("sun");
        setCreditsArray(['sunCloudCredits', 'morningChirpsSoundCredits']);
    };
    function setRainyWeather() {
        setWeather("rain");
        setCreditsArray(['rainImageCredits', 'thunderImageCredits', 'rainThunderSoundCredits']);
    };
    function setSnowyWeather() {
        setWeather("snow");
        setCreditsArray(['snowImageCredits', 'snowSoundCredits']);
    };

    const renderTooltip = props => (
        <Tooltip {...props}>{t('creditsTooltip')}</Tooltip>
    );
    

    return (
        <ThemeProvider theme={weatherTypes[weather]}>
            <Page>
                <div className="nonWeatherContainer">
                    <ToggleContainer id="toggleContainerResponsive">
                        <Toggle onClick={setSnowyWeather} style={weather === "snow" ? {backgroundColor: "#ff69b4"} : {backgroundColor: "#393e46"}}>
                            <i style={{color: "#fff", fontSize: "1.5rem"}}><BsSnow/></i>
                        </Toggle>                    
                        <Toggle onClick={setSunnyWeather} style={weather === "sun" ? {backgroundColor: "#ff69b4"} : {backgroundColor: "#393e46"}}>
                            <i style={{color: "#fff", fontSize: "1.5rem"}}><BsFillSunFill/></i>
                        </Toggle>
                        <Toggle onClick={setRainyWeather} style={weather === "rain" ? {backgroundColor: "#ff69b4"} : {backgroundColor: "#393e46"}}>
                            <i style={{color: "#fff", fontSize: "1.5rem"}}><IoThunderstorm/></i>
                        </Toggle>
                        <Toggle onClick={onTogglePlay} style={{backgroundColor: "#393e46"}}>
                            <i style={{color: "#fff", fontSize: "1.5rem"}}>
                                {playing === true ? <VscUnmute/> : <VscMute/>}                            
                            </i>
                        </Toggle>
                    </ToggleContainer>
                    {/* <div className="animate__animated animate__fadeIn myImage"></div>     */}
                    <div className="animate__animated animate__fadeIn myTextContainer">
                        <IntroText>{t('introTextHi')}</IntroText>
                        <IntroText style={{borderBottom: "2px solid #393e46"}}>{t('introTextAram')}</IntroText>
                        <IntroText style={{fontSize: "6vh"}}>{t('introTextSubheader')}</IntroText>
                    </div> 

                    <div className="creditsToggle">
                        <OverlayTrigger placement="top" overlay={renderTooltip}>
                            <Toggle onClick={toggleCredits} style={{backgroundColor: "#393e46"}}>
                                <RiCreativeCommonsLine style={{color: "#fff", fontSize: "2.5rem"}}/>  
                            </Toggle>
                        </OverlayTrigger>
                        {isCreditsVisible === true && !isLoading ? <CreditsModal onClose={toggleCredits} creditsArray={creditsArray}></CreditsModal> : <div/>}
                    </div>
                </div>
                
                
                {weather === "snow" && !isLoading ? <div className="mySnowyWeatherContainer">
                        <MakeItSnow />
                            <div className="mySnowyWeather"/>
                                <ReactHowler
                                    src={SnowSound}
                                    playing={playing}
                                    volume={volume}
                                    loop={true}
                                />
                            </div> : <div/>}
                {weather === "sun" && !isLoading ? <div className="mySunnyWeatherContainer">
                        <div className="mySunnyWeather"/>
                        <div className="cloudsContainer">
                            <div className="firstCloud"></div>
                            <div className="secondCloud"></div>
                            <div className="thirdCloud"></div>
                        </div>
                        <ReactHowler
                            src={SunSound}
                            playing={playing}
                            volume={volume}
                            loop={true}
                        />
                        {/* <div className="skylineContainer">                        
                            <div className="animate__animated animate__slideInUp animate__delay-2s skyline"/>
                        </div> */}
                    </div> : <div/>}
                {weather === "rain" && !isLoading ? <div className="myRainyWeatherContainer">
                        <div className="myRainyWeather"/>
                        <ReactHowler
                            src={HeavyRainSound}
                            playing={playing}
                            volume={volume}
                            loop={true}
                        />
                        <div className="thunderBuddy"/>
                        <ReactHowler
                            src={ThunderClapSound}
                            playing={playing}
                            volume={volume}
                            loop={true}
                        />
                    </div> : <div/>}
            </Page>
        </ThemeProvider>
    );
}

export default LandingPage;