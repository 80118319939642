import React, { useState, useEffect } from 'react';
import './skills.style.css';
import 'animate.css';
import LineTo from 'react-lineto';

import ReactHowler from 'react-howler';
import { VscUnmute, VscMute } from 'react-icons/vsc';
import ArrowExpandSound from "../../Assets/arrowExpandSound.mp3";
import SpaceSound from "../../Assets/spaceSound.mp3";

import { Particles} from "react-tsparticles";

import { RiCreativeCommonsLine } from 'react-icons/ri';
import CreditsModal from '../../Components/CreditsModal/creditsModal';
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

import { useTranslation } from "react-i18next";
import useWindowSize from '../../Helpers/getWindowSize';

const getResponsiveFrontendAnchorTo = (viewportMode) => {
  if (viewportMode === "mobile")
    return "50% 28%";
  else if (viewportMode === "ipad")
    return "50% 35%";
  else if (viewportMode === "medDevice")
    return "50% 50%";
  else if (viewportMode === "laptop")
    return "center";
  else if (viewportMode === "desktop")
    return "center";
}
const getResponsiveBackendAnchorTo = (viewportMode) => {
  if (viewportMode === "mobile")
    return "50% 31%";
  else if (viewportMode === "ipad")
    return "50% 40%";
  else if (viewportMode === "medDevice")
    return "50% 50%";
  else if (viewportMode === "laptop")
    return "center";
  else if (viewportMode === "desktop")
    return "center";
}

const SkillsPage = () => {
    const [showFrontend, setFrontend] = useState(false);
    const [showBackend, setBackend] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [playing, setPlaying] = useState(false);
    // const [playArrow, setPlayArrow] = useState(false);
    const [volume, setVolume] = useState(0.5);
    const [isCreditsVisible, setCreditsVisible] = useState(false);

    const windowSize = useWindowSize();

    const creditsArray = ['spaceSoundCredits', 'frontendIconCredits', 'backendIconCredits'];

    const { t } = useTranslation();

    const onTogglePlay = () => {
      setPlaying(!playing);
    };

    const toggleCredits = () => {
      setCreditsVisible(!isCreditsVisible);
    } 

    const frontendButtonHandler = () => {
      setFrontend(current => !current)
    }

    const backendButtonHandler = () => {
      setBackend(current => !current)
    }

    useEffect(() => {
      const handleLoading = () => {
        setIsLoading(false);
      };

      if (document.readyState === "complete") {
          handleLoading();
      } else {
          window.addEventListener("load",handleLoading);
          return () => window.removeEventListener("load",handleLoading);
      }
    },[]);

    const renderTooltip = props => (
      <Tooltip {...props}>{t('creditsTooltip')}</Tooltip>
    );

    // shouldIPlayArrow = () => {
    //   // if (showFrontend)
    //   //   setPlayArrow(true);
    //   // if (showBackend)
    //   //   setPlayArrow(true);
    //   // if (showBackend && showFrontend || (!showBackend || !showFrontend))
    //   //   setPlayArrow(false);
    // };

    return (
        <div className="skillsContainer">
          <div className="iconsContainer">
            <i onClick={onTogglePlay} style={{color: "#fff", fontSize: "1.5rem"}}>
                                {playing === true ? <VscUnmute/> : <VscMute/>}                            
                            </i>
             
            <div onClick={frontendButtonHandler} className="animate__animated animate__fadeIn animate__delay-2s circle"></div>
            <div style={showFrontend === true && isLoading === false ? {visibility: "visible"} : {visibility: "hidden"}}>
              <a className="react" target="_blank" rel="noopener noreferrer" href="https://reactjs.org/"></a>
              <a className="angular" target="_blank" rel="noopener noreferrer" href="https://angular.io/"></a>
              <a className="typescript" target="_blank" rel="noopener noreferrer" href="https://www.typescriptlang.org/"></a>
              <a className="bootstrap" target="_blank" rel="noopener noreferrer" href="https://getbootstrap.com/"></a>

              {showFrontend === true && isLoading === false ? <>
              <LineTo className="fadingEffect" fromAnchor={getResponsiveFrontendAnchorTo(windowSize)} toAnchor="left" borderColor="#00FFFF" borderWidth={2} from="circle" to="react"/>
              <LineTo className="fadingEffect" fromAnchor={getResponsiveFrontendAnchorTo(windowSize)} toAnchor="center" borderColor="#00FFFF" borderWidth={2} from="circle" to="angular" />
              <LineTo className="fadingEffect" fromAnchor={getResponsiveFrontendAnchorTo(windowSize)} toAnchor="center" borderColor="#00FFFF" borderWidth={2} from="circle" to="typescript" />
              <LineTo className="fadingEffect" fromAnchor={getResponsiveFrontendAnchorTo(windowSize)} toAnchor="left" borderColor="#00FFFF" borderWidth={2} from="circle" to="bootstrap" /> </> : null}            
            </div>

            <div onClick={backendButtonHandler} className="animate__animated animate__fadeIn animate__delay-2s backend"></div> 
            <div style={showBackend === true && isLoading === false ? {visibility: "visible"} : {visibility: "hidden"}}>
              <a className="python" target="_blank" rel="noopener noreferrer" href="https://www.python.org/"></a>
              <a className="csharp" target="_blank" rel="noopener noreferrer" href="https://docs.microsoft.com/en-us/dotnet/csharp/"></a>
              <a className="java" target="_blank" rel="noopener noreferrer" href="https://www.java.com/en/"></a>
              <a className="sql" target="_blank" rel="noopener noreferrer" href="https://www.w3schools.com/sql/"></a>

              {showBackend === true && isLoading === false ? <>
              <LineTo className="fadingEffect" fromAnchor={getResponsiveBackendAnchorTo(windowSize)} toAnchor="center" borderColor="#2596be" borderWidth={2} from="backend" to="python" /> 
              <LineTo className="fadingEffect" fromAnchor={getResponsiveBackendAnchorTo(windowSize)} toAnchor="left" borderColor="#2596be" borderWidth={2} from="backend" to="csharp" />
              <LineTo className="fadingEffect" fromAnchor={getResponsiveBackendAnchorTo(windowSize)} toAnchor="left" borderColor="#2596be" borderWidth={2} from="backend" to="java" />
              <LineTo className="fadingEffect" fromAnchor={getResponsiveBackendAnchorTo(windowSize)} toAnchor="left" borderColor="#2596be" borderWidth={2} from="backend" to="sql" /> </> : null}              
            </div>
          </div>
          <ReactHowler
            src={ArrowExpandSound}
            playing={playing && (showBackend || showFrontend)}
            volume={volume}
            loop={false}
            rate={0.8}
          />
          <ReactHowler
            src={SpaceSound}
            playing={playing}
            volume={volume}
            loop={true}
          />

          <div className="creditsToggleContainer">            
            <OverlayTrigger placement="top" overlay={renderTooltip}>
              <button className="creditsToggle" onClick={toggleCredits} style={{backgroundColor: "#393e46"}}>
                  <RiCreativeCommonsLine style={{color: "#fff", fontSize: "2.5rem"}}/>                
              </button>
            </OverlayTrigger>
            {isCreditsVisible === true && !isLoading ? <CreditsModal onClose={toggleCredits} creditsArray={creditsArray}></CreditsModal> : <div/>}  
          </div>

          <div className="particlesContainer">
            <Particles
              options={{
                fpsLimit: 60,
                
                background: {
                  color: {
                    value: "#000"
                  }
                },
                responsive: [ 
                  {
                    // Smaller devices ----------------------------------------------------------  
                    maxWidth: 600,
                    options: {
                      interactivity: {
                        events: {
                          onClick: {
                            enable: true,
                            mode: "repulse"
                          },
                        },
                        modes: {
                          repulse: {
                            distance: 2,
                            duration: 0.25
                          }
                        }
                      },
                      emitters: [
                        {
                          direction: "top",
                          position: {
                            x: 20,
                            y: 100
                          },
                          rate: {
                            delay: 0.05,
                            number: 50
                          },
                          size: {
                            width: 50,
                            height: 100
                          },
                          particles: {
                            color: {
                              value: "#00FFFF"
                            },
                            size: {
                              value: 5
                            },
                            links: {
                              color: "#00FFFF",
                              enable: true,
                              distance: 50,
                            },
                            opacity: {
                              value: 0.5
                            },
                            move: {
                              speed: 2,
                              angle: 30,
                              random: true,
                              straight: true,
                              outMode: "destroy"
                            },
                            number: {
                              value: 2
                            }
                          }
                        },
                        {
                          direction: "bottom",
                          position: {
                            x: 80,
                            y: 50
                          },
                          rate: {
                            delay: 0.05,
                            number: 50
                          },
                          size: {
                            width: 50,
                            height: 100
                          },
                          particles: {
                            color: {
                              value: "#2596be"
                            },
                            size: {
                              value: 5
                            },
                            links: {
                              enable: true,
                              color: "#2596be",
                              distance: 50,
                            },
                            opacity: {
                              value: 0.5
                            },
                            move: {
                              speed: 2,
                              angle: 30,
                              random: true,
                              straight: true,
                              outMode: "destroy"
                            },
                            number: {
                              value: 2
                            }
                          }
                        },
                      ]
                    }
                  },
                  // Phones/Ipads etc----------------------------------------------------------         
                  {
                    maxWidth: 991,
                    options: {
                      interactivity: {
                        events: {
                          onClick: {
                            enable: true,
                            mode: "repulse"
                          },
                        },
                        modes: {
                          repulse: {
                            distance: 2,
                            duration: 0.25
                          }
                        }
                      },
                      emitters: [
                        {
                          direction: "top",
                          position: {
                            x: 20,
                            y: 100
                          },
                          rate: {
                            delay: 0.05,
                            number: 50
                          },
                          size: {
                            width: 50,
                            height: 100
                          },
                          particles: {
                            color: {
                              value: "#00FFFF"
                            },
                            size: {
                              value: 5
                            },
                            links: {
                              color: "#00FFFF",
                              enable: true,
                              distance: 50,
                            },
                            opacity: {
                              value: 0.5
                            },
                            move: {
                              speed: 2,
                              angle: 30,
                              random: true,
                              straight: true,
                              outMode: "destroy"
                            },
                            number: {
                              value: 2
                            }
                          }
                        },
                        {
                          direction: "bottom",
                          position: {
                            x: 80,
                            y: 50
                          },
                          rate: {
                            delay: 0.05,
                            number: 50
                          },
                          size: {
                            width: 50,
                            height: 100
                          },
                          particles: {
                            color: {
                              value: "#2596be"
                            },
                            size: {
                              value: 5
                            },
                            links: {
                              enable: true,
                              color: "#2596be",
                              distance: 50,
                            },
                            opacity: {
                              value: 0.5
                            },
                            move: {
                              speed: 2,
                              angle: 30,
                              random: true,
                              straight: true,
                              outMode: "destroy"
                            },
                            number: {
                              value: 2
                            }
                          }
                        },
                      ]
                    }
                  }         
                ],
                particles: {
                  number: {
                    value: 0,
                    density: {
                      enable: false,
                      value_area: 800
                    }
                  },
                  color: {
                    value: "#fff"
                  },
                  shape: {
                    type: "circle",
                    stroke: {
                      width: 0,
                      color: "#fff"
                    },
                    polygon: {
                      nb_sides: 5
                    },
                    image: {
                      src: "https://cdn.matteobruni.it/images/particles/github.svg",
                      width: 100,
                      height: 100
                    }
                  },
                  opacity: {
                    value: 0.5,
                    random: false,
                    anim: {
                      enable: false,
                      speed: 1,
                      opacity_min: 0.1,
                      sync: false
                    }
                  },
                  size: {
                    value: 5,
                    random: true,
                    anim: {
                      enable: false,
                      speed: 40,
                      size_min: 0.1,
                      sync: false
                    }
                  },
                  line_linked: {
                    enable: true,
                    distance: 75,
                    color: "#fff",
                    opacity: 0.4,
                    width: 1
                  },
                  move: {
                    enable: true,
                    speed: 2,
                    direction: "top",
                    random: false,
                    straight: true,
                    out_mode: "out",
                    attract: {
                      enable: false,
                      rotateX: 600,
                      rotateY: 1200
                    }
                  }
                },
                interactivity: {
                  events: {
                    onClick: {
                      enable: true,
                      mode: "repulse"
                    },
                    onDiv: {
                      elementId: "repulse-div",
                      enable: false,
                      mode: "repulse"
                    },
                    onHover: {
                      enable: true,
                      mode: "connect",
                      parallax: {
                        enable: false,
                        force: 2,
                        smooth: 10
                      }
                    },
                    resize: true
                  },
                  modes: {
                    bubble: {
                      distance: 240,
                      duration: 3,
                      opacity: 8,
                      size: 6,
                      speed: 3
                    },
                    connect: {
                      distance: 80,
                      lineLinked: {
                        opacity: 0.5
                      },
                      radius: 60
                    },
                    grab: {
                      distance: 400,
                      lineLinked: {
                        opacity: 1
                      }
                    },
                    light: {
                        area: {
                            gradient: {
                                start: {
                                    value: "#ffff"
                                },
                                stop: {
                                    value: "#ffffff"
                                },
                                radius: 1000                   
                            }
                        },                                                         
                    },
                    push: {
                      quantity: 4
                    },
                    remove: {
                      quantity: 2
                    },
                    repulse: {
                      distance: 400,
                      duration: 0.4
                    },
                    slow: {
                      active: false,
                      radius: 0,
                      factor: 1
                    }
                  }
                },
                retina_detect: true,
                emitters: [
                  {
                    direction: "top",
                    position: {
                      x: 20,
                      y: 100
                    },
                    rate: {
                      delay: 0.05,
                      number: 50
                    },
                    size: {
                      width: 50,
                      height: 100
                    },
                    particles: {
                      color: {
                        value: "#00FFFF"
                      },
                      size: {
                        value: 5
                      },
                      links: {
                        id: "green",
                        color: "#00FFFF",
                        enable: true
                      },
                      opacity: {
                        value: 0.5
                      },
                      move: {
                        speed: 2,
                        angle: 30,
                        random: true,
                        straight: true,
                        outMode: "destroy"
                      }
                    }
                  },
                  {
                    direction: "bottom",
                    position: {
                      x: 80,
                      y: 50
                    },
                    rate: {
                      delay: 0.05,
                      number: 50
                    },
                    size: {
                      width: 50,
                      height: 100
                    },
                    particles: {
                      color: {
                        value: "#2596be"
                      },
                      size: {
                        value: 5
                      },
                      links: {
                        enable: true,
                        id: "red",
                        color: "#2596be"
                      },
                      opacity: {
                        value: 0.5
                      },
                      move: {
                        speed: 2,
                        angle: 30,
                        random: true,
                        straight: true,
                        outMode: "destroy"
                      }
                    }
                  }
                ]
              }
              }
              />
            </div>            
        </div>
    );
}

export default SkillsPage;