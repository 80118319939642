import { withStyles } from '@material-ui/core/styles';
import React, { Component, Suspense } from 'react';
import './App.css';

import { 
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from 'react-router-dom';

//Pages
import LandingPage from './Pages/LandingPage/landing';
import NotFoundPage from './Pages/404';
import AboutPage from './Pages/AboutPage/about';
import SkillsPage from './Pages/SkillsPage/skills';
import Navbar from './Components/Navbar/navbar';
import ProjectsPage from './Pages/ProjectsPage/projects';
import LanguagePicker from './Components/LanguagePicker/languagePicker';


const globalStyles = theme => ({
  root: {
    height: "100vh",
    display: "flex", 
    justifyContent: "center",
    fontSize: "2rem",
    color: "white",
  },

  languagePicker: {
    right: "3%",
    position: "fixed",
    zIndex: "5"
  },

  copyright: {
    position: "absolute",
    bottom: "0",
    left: "0",
    fontSize: "1rem"    
  }
});

class App extends Component {
  state = {
    searchNodes: ""
  };

  render() {
    const { classes } = this.props;
    return (
      <Suspense fallback="Loading">
        <Router>
          <div className={classes.root} id="root">

            <Switch>
              <Route exact path="/" component={LandingPage}></Route>
              <Route exact path="/about" component={AboutPage}></Route>
              <Route exact path="/skills" component={SkillsPage}></Route>
              <Route exact path="/projects" component={ProjectsPage}></Route>

              <Route exact path="/404" component={NotFoundPage}></Route>
              <Redirect to="/"></Redirect>
            </Switch>     

            <div className={classes.languagePicker} id="languagePicker">
              <LanguagePicker></LanguagePicker>       
            </div>
            
            <div className="myNavbar">
              <Navbar></Navbar>
            </div>    

            <div className={classes.copyright} style={{fontWeight: "14", fontFamily: "neuemachina-ultrabold"}} id="copyright">
              <p>© Aram {(new Date().getFullYear())}</p>
            </div>

          </div>
        </Router>
      </Suspense>
    )
  }
}

export default withStyles(globalStyles) (App);
