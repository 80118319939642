import React, { useEffect } from 'react';
import './creditsModal.style.css';

import { useTranslation } from "react-i18next";

const CreditsModal = ({ onClose, creditsArray }) => {
    const { t} = useTranslation();
    
    useEffect(() => {
		function onKeyDown(event) {
			if (event.keyCode === 27) {
				// Close the modal when the Escape key is pressed
				onClose();
			}
		}
		// Not necessary to prevent scrolling on website but just in case
		document.body.style.overflow = "hidden";
		document.addEventListener("keydown", onKeyDown);

		// Clear things up when unmounting this component
		return () => {
			document.body.style.overflow = "visible";
			document.removeEventListener("keydown", onKeyDown);
		};
	});

    return (
        <div className="modal fade creditsContainer"  tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content creditsModal">
                <div className="modal-header">
                    <h5 className="modal-title">{t('credits')}</h5>
                </div>
                <div className="modal-body creditsBody">
                    <ul>{creditsArray?.map((creditsItem) => <li key={creditsItem}>{t(creditsItem)}</li>)}</ul>
                </div>
                <div className="modal-footer">
                    <button type="button" onClick={onClose} className="btn btn-secondary" style={{backgroundColor: "#393e46"}} data-dismiss="modal">{t('close')}</button>
                </div>
                </div>
            </div>
        </div>
    );
}

CreditsModal.propTypes = {};

export default CreditsModal;