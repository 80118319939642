import React, { Component } from 'react';

class ProjectsPage extends Component {    

    render = () => {
        return (
            <div className="projectsContainer">
            </div>
        )
    }
}

export default (ProjectsPage);