import React, { Component } from 'react';
import './about.style.css';
import 'animate.css';

import { withTranslation  } from "react-i18next";
import i18next from 'i18next';

import { RiCreativeCommonsLine } from 'react-icons/ri';
import CreditsModal from '../../Components/CreditsModal/creditsModal';

class AboutPage extends Component {    
    // we keep track of x and y coordinates for the blue circle - the main one
  // and the trailing circle - the white one
  // for simplicity, they are initialzed to (0, 0), the top left corner of the viewport
  state = {
    xMain: 0,
    yMain: 0,
    xFirstTrailing: 0,
    yFirstTrailing: 0,
    xSecondTrailing: 0,
    ySecondTrailing: 0,

    isCreditsVisible: false
  }

  
  
  handleMouseMove = (e) => {
    // Using pageX and pageY will cause glitching when you scroll the window down
    // because it measures the distance from the top left rendered corner, not
    // top left visible corner
    const { clientX, clientY } = e;

    // we set the main circle coordinates as soon as the mouse is moved
    this.setState({
      xMain: clientX,
      yMain: clientY,
    }, () => {
      // this callback is invoked after the first setState finishes
      // 
      // here we schedule saving the trailing coordinates in state 100ms  
      // after the main coordinates have been set to simulate the trailing
      setTimeout(() => {
        this.setState({
          xFirstTrailing: clientX,
          yFirstTrailing: clientY,
        })
      }, 100);

      setTimeout(() => {
        this.setState({
          xSecondTrailing: clientX,
          ySecondTrailing: clientY,
        })
      }, 200);
    })
  }

    render = () => {
        const {
            xMain,
            yMain,
            xFirstTrailing,
            yFirstTrailing,
            xSecondTrailing,
            ySecondTrailing
          } = this.state;

        const creditsArray = ['aramImage1Credits', 'aramImage2Credits'];

        const { t } = this.props;
        const getCurrentLng = () => i18next.language || window.localStorage.i18nextLng || '';
        return (
            <div className="aboutContainer" onMouseMove={e => this.handleMouseMove(e)}>

                <div className='cursors'>
                    <div 
                        className='cursor'
                        style={{ 
                        left: xMain, 
                        top: yMain,
                        }}
                    />
                    
                    <div 
                        className='cursor'
                        style={{ 
                        left: xFirstTrailing, 
                        top: yFirstTrailing,
                        }}
                    />

                    <div 
                        className='cursor'
                        style={{ 
                        left: xSecondTrailing, 
                        top: ySecondTrailing,
                        }}
                    />
                </div>

                <div className="animate__animated animate__fadeIn myIntroText" dir={getCurrentLng() === "ar" ? "rtl" : "ltr"}>
                  {/* Ugly workaround as the text in arabic loses proportions and becomes uneditable in json */}
                  {getCurrentLng() === "ar"? 
                    <p> اعمل في<a className="bfLink" target="_blank" rel="noopener noreferrer" href="https://career.bf.se/"> باركفورس </a>
                    كمبرمج على تطبيقات الويب والتطوير على صعيد الويب والأجهزة المحمولة .
                    {/* <br></br>أنا أقدّر التنوع وأستمتع دائما بإضافة مهارات جديدة الى عملي . */}
                    </p> :
                    <p>{t('aboutPageIWorkAt')}<a className="bfLink" target="_blank" rel="noopener noreferrer" href="https://career.bf.se/">Barkfors</a>
                    {t('aboutPageWhatIDo')} 
                    {/* <br></br>{t('aboutPageVersatility')} */}
                    </p>
                  }
                </div>
                <div className="animate__animated animate__fadeIn firstImage"></div>

                <div className="animate__animated animate__fadeIn secondImage"></div>
                <div className="animate__animated animate__fadeIn whoamiText" dir={getCurrentLng() === "ar" ? "rtl" : "ltr"}>
                  {getCurrentLng() === "ar"? 
                    <p> بالنسبة لمفهومي الخاص أعتقد ان 
                    فهم الهيكلة العامة لمنظور جسم الانسان تشبه أساس بناء برامج الكمبيوتر 
                    وهذا هو السبب في أن كمال الأجسام والبرمجة هما من شغفي.</p> :
                    <p>
                    {t('aboutPageStimulation')} 
                    {t('aboutPagePassions')}</p>
                  }
                    
                </div>

                <div className="socialMediaContainer">
                    <a className="socialIcons" target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/aram_emw/">
                        <i className="fa fa-instagram instagramIcon" aria-hidden="true"></i>
                        <span></span>
                    </a>
                    <a className="socialIcons" target="_blank" rel="noopener noreferrer" href="https://github.com/imAraam">
                        <i className="fa fa-github githubIcon" aria-hidden="true"></i>
                        <span></span>
                    </a>
                    <a className="socialIcons" target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/in/aram-albaradie-2b8805172/">
                        <i className="fa fa-linkedin linkedinIcon" aria-hidden="true"></i>
                        <span></span>
                    </a>
                </div>

                <div className="creditsToggleContainer">
                  <button className="creditsToggle" onClick={() => this.setState({ isCreditsVisible: !this.state.isCreditsVisible })} style={{backgroundColor: "#393e46"}}>
                      <RiCreativeCommonsLine style={{color: "#fff", fontSize: "2.5rem"}}/>                        
                  </button>
                  {this.state.isCreditsVisible === true ? 
                  <CreditsModal onClose={() => this.setState({ isCreditsVisible: !this.state.isCreditsVisible })} creditsArray={creditsArray}></CreditsModal> : <div/>}
                </div>

          </div>
        )
    }
}

export default withTranslation()(AboutPage);