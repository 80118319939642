
import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import cookies from 'js-cookie';
import { ReactComponent as Globe } from '../../Assets/globe.svg';

const languages = [
    {
      code: 'en',
      name: 'English',
      country_code: 'gb'
    },
    {
      code: 'sv',
      name: 'Svenska',
      country_code: 'se'
    },
    {
      code: 'ar',
      name: 'العربية',
      country_code: 'sy',
      dir: 'rtl'
    }
];

const LanguagePicker = (props) => {
    const { location } = props;
    
    const currentLanguageCode = cookies.get('i18next') || 'en';
    // const currentLanguage = languages.find((l) => l.code === currentLanguageCode);
    const { t } = useTranslation();
    
    useEffect(() => { 
        //document.body.dir = currentLanguage.dir || 'ltr' //To change direction of text
        document.title = t('websiteTitle');
    }, [t]);

    if (location.pathname.match(/skills/)){ //Hides component on skills page
        return null;
    }


    return (
        <div className="container">
            <div className="language-select">
                <div className="d-flex justify-content-end align-items-center language-select-root">
                    <div className="dropdown">
                        <button className="btn btn-link dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" 
                        aria-expanded="false" style={{color: "#fff"}}>
                            <Globe width="40" height="40" fill="#ffff" className="bi bi-globe"/>
                        </button>
                        <ul className="dropdown-menu dropdownMenu" aria-labelledby="dropdownMenuButton1">
                            <li>
                                <span className="dropdown-item-text">{t('language')}</span>
                            </li>
                            {languages.map(({code, name, country_code}) => (
                                <li key={country_code}>
                                    <a
                                        href="#"
                                        className="dropdown-item"
                                        disabled= {code === currentLanguageCode}
                                        onClick={() => {i18next.changeLanguage(code)}}
                                    >
                                        <div className={`fi fi-${country_code} mx-2`}></div>
                                        {name}
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}
LanguagePicker.propTypes = {};
export default withRouter(LanguagePicker);
