import React from 'react';

const NotFoundPage = () => {
    return (
        <div>
            <h3>Hello the name is 404</h3>
            <small>404 Not Found</small>
        </div>
    );
}

export default NotFoundPage;